@import 'theme.scss';

.Footer {
  background: var(--color-primary);
  color: $color-white;
  font-size: 1.175rem;
  padding: 3rem 1rem;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 10;
}

.Footer a {
  color: var(--color-light);
}

.Footer a:active,
.Footer a:focus,
.Footer a:hover {
  color: var(--color-light);
}

.Footer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Footer__disclaimer {
  background: $color-white;
  border-radius: 0.25rem;
  color: $color-black;
  margin: 0 1rem 2rem;
  max-width: 40rem;
  padding: 1rem;
}

.Footer__disclaimer a {
  color: var(--link-color);
}

.Footer__disclaimer a:hover {
  color: var(--link-color--hover);
}

.Footer__Links {
  list-style: none;
  padding: 0;
}

.Footer__Links > li {
  margin-bottom: 1rem;
}

.Footer__Links > li a {
  color: $color-white;
  font-weight: bold;
  text-decoration: none;
}

.Footer__Locale {
  align-items: center;
  display: flex;
  margin-top: 1.5rem;
}

.Footer__Locale svg {
  margin-right: 0.5rem;
}

.Footer__LocaleSwitcher {
  margin-left: 0.5rem;
}

@media (min-width: 768px) {
  .Footer__Links > li {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .Footer--with-sidebar {
    margin-left: 18rem;
    width: calc(100% - 18rem);
  }
}

@media (min-width: 992px) {
  .Footer--with-sidebar {
    margin-left: 22rem;
    width: calc(100% - 22rem);
  }
}

@media print {
  .Footer {
    display: none;
  }
}
