@import 'theme.scss';

.GoogleLoginButton {
  align-items: center;
  background: rgb(57, 122, 242); /* Google blue */
  border: 2px solid rgb(57, 122, 242);
  border-radius: 0.25rem;
  color: $color-white;
  cursor: pointer;
  display: flex;
  font-family: 'Roboto';
  font-size: 1rem;
  padding: 0;
  width: 100%;
}

.GoogleLoginButton__Content {
  align-items: center;
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: 2.5rem;
  width: 100%;
}

.GoogleLoginButton:hover {
  background: rgb(44, 92, 208);
  border-color: rgb(44, 92, 208);
}

.GoogleLoginButton__icon {
  align-items: center;
  background: $color-white;
  border-radius: 0.25rem 0 0 0.25rem;
  display: flex;
  height: 100%;
  justify-content: center;
}

.GoogleLoginButton__inner {
  flex: 1;
}
